<template>
  <div class="my-10">
    <div class="w-full">
      <div class="flex items-center">
        <h1 class="w-2/12">Date</h1>
        <h1 class="w-2/12">Name of Deceased</h1>
        <h1 class="w-2/12">Waybill Number</h1>
        <h1 class="w-2/12">Name of Airline</h1>
        <h1 class="w-2/12">Location</h1>
        <h1 class="w-2/12">Received By</h1>
      </div>
      <template v-for="repriatration in repriatrations">
        <div
          class="flex items-center bg-white py-3 px-5 rounded-md my-4 hover:shadow-md cursor-pointer"
          :key="repriatration.id"
        >
          <div class="w-2/12 flex items-center gap-3">
            {{ parseDate(repriatration.created_at) }}
          </div>
          <div class="w-2/12">
            {{ repriatration.name_of_deceased }}
          </div>
          <div class="w-2/12">
            {{ repriatration.air_waybill_number }}
          </div>
          <div class="w-2/12">
            {{ repriatration.airline }}
          </div>
          <div
            class="w-2/12 flex items-center justify-center gap-2 bg-primary bg-opacity-10 border-primary border text-primary font-bold py-2 px-5 rounded-3xl"
          >
            <location-icon />
            {{ repriatration.branch.branch_name }}
          </div>
          <div class="w-2/12 ml-10">
            {{ repriatration.staff.firstname }}
            {{ repriatration.staff.lastname }}
          </div>
        </div>
      </template>
    </div>
    <div class="flex justify-end gap-3">
      <template v-for="page in Math.ceil(total / perPage)">
        <div
          class="bg-white py-2 px-4 cursor-pointer rounded-md"
          :key="page"
          :class="
            page === currentPage
              ? 'bg-primary cursor-default text-white shadow-md'
              : ''
          "
          @click="$emit('fetchPage', page)"
        >
          {{ page }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "RepatriationTable",
  components: {
    LocationIcon: () => import("@/assets/icons/LocationIcon.vue"),
  },
  props: {
    allRepriatration: {
      required: true,
      type: Array,
    },
    perPage: {
      required: true,
      type: Number,
    },
    total: {
      required: true,
      type: Number,
    },
    currentPage: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      pageNumber: 0,
    };
  },
  methods: {
    parseDate(date) {
      return moment(date).format("D MMM, YYYY");
    },
  },
  computed: {
    repriatrations() {
      return this.allRepriatration;
    },
  },
};
</script>
